import React from 'react';
import image from './nfl-background.jpg';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
    margin: '0',
    padding: '0',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    padding: '1rem 2rem',
    borderRadius: '0.5rem',
    fontFamily: 'UnitedSansReg-Heavy,Montserrat-Bold, sans-serif',
    textTransform: 'uppercase',
    maxWidth: '30rem',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2rem',
    },
  },
  logo: {
    display: 'block',
    margin: '0 auto 1rem',
    width: '100%',
    maxWidth: '10rem',
    height: 'auto',
  },
  h1: {
    textAlign: 'center',    
  },
  link: {
    color: '#000',
    textDecoration: 'none',
    wordBreak: 'break-all',
    alignItems: 'center',
    fontSize: 'small',
    '@media (min-width: 768px)': {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'none',
    '> h1': {
    marginRight: '1rem',
    },
    '> img': {
    height: '2rem',
    },
    },
  },
};


function App() {
  return (
    <div style={styles.container}>
      <div style={styles.block}>
        <img style={styles.logo} src=".\Images\NFL_Shield_web.png" alt="Logo" />
       
        
        <a href="https://nflplaybook.nfl.net/" style={styles.link}><h1 style={styles.h1}>Click here to login to </h1><img src=".\Images\playbook.png" alt=""></img></a>
      </div>
    </div>
  );
};

export default App;